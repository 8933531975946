import React, { useEffect } from "react";
import Portfolio from "../components/Portfolio";
import Team from "../components/Team";
import Layout from "../components/Layout";
import BlogPreview from "../components/BlogPreview";
import { Helmet } from "react-helmet";

const IndexPage = () => {
  //
  //
  //
  //
  useEffect(() => {
    setTimeout(() => {
      window.location.href = `https://forgelightmarketing.com/`;
    }, 6000);
  }, []);

  //
  //

  return (
    <>
      <main className=" grid min-h-screen place-items-center bg-white px-6 py-24">
        <div className="animate-fade w-full text-center">
          <div class="flex mx-auto w-full lg:w-1/2">
            <img
              src="https://imgix.cosmicjs.com/8ff573e0-09e6-11ee-a7a3-8593d395588b-Logo-vector.svg"
              className="w-full"
            />
          </div>
          <p className=" text-2xl font-body text-orange-500 uppercase">
            CONSULTFGC IS NOW ↓
          </p>
          <div className="mt-10 flex justify-center">
            <div class="flex w-full lg:w-1/2">
              <img
                src="https://imgix.cosmicjs.com/122c9940-ee03-11ef-a238-c1ad09f4b1ad-FLMG-Wordmark.svg"
                className="w-full"
              />
            </div>
          </div>
          <p className="mt-10 animate-pulse text-pretty text-lg font-body font-medium text-black sm:text-xl/8">
            You will be redirected now...
          </p>
        </div>
      </main>
    </>
  );
};
//

export default IndexPage;
